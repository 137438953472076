/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Alert, AlertColor, Box, Button, CircularProgress, Container, Divider, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { AvatarGroup, ExpandedListValues, BoxContent, ControllQuantity, Feedback, HeaderPage, MessageBox, Price, ProductInline, Template, Image, VariationItem, TitleSectionShopping } from '@components';
import { sx } from './sx';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { skeleton } from './skeleton';
import { useUser } from '@hooks/user';
import { files, formatShortDate, money, styles } from '@utils';
import { AddItemDataProps, CartDataProps, StoreItemCartProps } from '@interfaces/Services';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { SelectedAdItemProps, ToggleStaticProps } from '@interfaces/index';
import { Item } from './styles';
import { useAds } from '@hooks/ads';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';

interface ChangeItemProps {
    action: 'add' | 'remove' | 'delete' | 'modal';
    item: AddItemDataProps;
};

const DEFAULTS = {
    LOADING_CART: {
        cart: false,
        deleteAllItems: false,
    },
};

const Cart: React.FC = () => {
    const { palette, typography } = useTheme();
    const navigate = useNavigate();
    const { cart, info } = useUser();
    const { ads } = useAds();
    const global = useGlobal();
    const applicationType = global.app.view.application.type;
    const { layout } = useLayout();

    const { css } = sx({
        viewType: applicationType,
    });
    const skel = skeleton();
    const mediaQuery = styles.mediaQueryCSS();

    const dialogChangesItem = React.useRef<ToggleStaticProps>(null);
    const dialogCartItems = React.useRef<ToggleStaticProps>(null);

    const [loadingChanges, setLoadingChanges] = React.useState(false);
    const [loadingCart, setLoadingCart] = React.useState(DEFAULTS.LOADING_CART);
    const [selectedAdItem, setSelectedAdItem] = React.useState(
        {} as SelectedAdItemProps,
    );
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        id: '',
        isLoadedModal: true,
        isLoadedQuantity: true,
        isLoadedDelete: true,
        type: 'success' as AlertColor,
        message: '',
    });
    const [allowProceed, setAllowProceed] = React.useState({
        proceed: true,
        items: [] as StoreItemCartProps[],
    });

    const [itemsDiscounts, setItemsDiscounts] = React.useState([] as StoreItemCartProps[]);

    const checkItemDiscountItems = (cartData: CartDataProps) => {
        if (cartData?.stores) {
            cartData.stores.map((store) => {
                const findedItems = store.items.filter((item) => item.itemDiscount?.sku);
                setItemsDiscounts(findedItems);
            });
        }
    };

    const checkNotAvailableItems = (cartData: CartDataProps) => {
        if (cartData?.stores) {
            // const stores = cartData?.stores?.map((store) => {
            //     return store.items.filter((item) => item.notAvailable);
            // })[0];

            const stores = cartData?.stores?.flatMap((item) => item.items.filter(({ notAvailable }) => notAvailable === true));


            if (stores.length > 0) {
                setAllowProceed({
                    proceed: false,
                    items: stores as StoreItemCartProps[],
                });
            }
        }
        setLoadingCart((prev) => prev = {
            ...prev,
            cart: true,
        });
    };

    const loadCart = async () => {
        const response = await cart.load({
            cartType: global.app.view.application.type,
        });
        if (!response?.error) {
            checkNotAvailableItems(response?.response.payload);
            checkItemDiscountItems(response?.response.payload);
        }
    };

    const handleClose = () => setOpen(false);

    const handleOpenChangeModal = async (item: SelectedAdItemProps) => {
        setSelectedAdItem(item);
        if (ads.byId.item.isLoaded && ads.byId.item.data._id === item.idAd) {
            dialogChangesItem.current?.show();
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                id: item.variationItemId,
                isLoadedModal: false,
            });
            await ads.byId.load({
                idAd: item.idAd,
            });
            dialogChangesItem.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                id: '',
                isLoadedModal: true,
            });
        }
    };

    const imageVariation = () => {
        const findItem = ads.byId.item.isLoaded && ads.byId.item.data?.variations?.find((variation) => variation._id === selectedAdItem.idVariation);
        if (findItem) {
            return files.load(findItem.images[0], 'server');
        } else {
            return files.load('', 'local');
        }
    };

    const changeItem = async ({ action, item }: ChangeItemProps) => {
        setLoadingChanges(true);
        setResponseData((prev) => prev = {
            ...prev,
            id: item.variationItemId,
            isLoadedModal: !(action === 'modal'),
            isLoadedDelete: !(action === 'delete'),
            isLoadedQuantity: !(!(action !== 'remove') || !(action !== 'add')),
        });
        let response = {} as ResponseAsyncProps | undefined;
        switch (action) {
            case 'add':
                response = await cart.handleItem.add(item);
                break;
            case 'modal':
                response = await cart.handleItem.add(item);
                break;
            case 'remove':
                response = await cart.handleItem.add(item);
                break;
            case 'delete':
                response = await cart.handleItem.delete(item);
                break;
        }
        if (!response?.error) {
            setResponseData({
                type: 'success',
                id: '',
                message: response?.response.message,
                isLoadedDelete: true,
                isLoadedModal: true,
                isLoadedQuantity: true,
            });
            setOpen(true);
            dialogChangesItem.current?.hide();
            setTimeout(() => {
                handleClose();
            }, 3000);
            await loadCart();
            setLoadingChanges(false);
        } else {
            setResponseData({
                id: '',
                type: response?.response?.type,
                message: response?.response?.message,
                isLoadedDelete: true,
                isLoadedModal: true,
                isLoadedQuantity: true,
            });
            setOpen(true);
            setLoadingChanges(false);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    const deleteAllItemsOnCart = async () => {
        let removedCount = 0;
        setLoadingCart((prev) => prev = {
            ...prev,
            deleteAllItems: true,
        });
        if (!allowProceed.proceed) {
            const itemsToDelete = allowProceed.items.map((item) => {
                return {
                    idAd: item.ad._id,
                    idVariation: item.variation._id,
                    variationItemId: item.variationItem._id,
                    quantity: item.quantity,
                };
            });

            for (const item of itemsToDelete) {
                try {
                    const response = await cart.handleItem.delete(item);
                    if (!response?.error) {
                        removedCount += 1;

                        if (removedCount === itemsToDelete.length) {
                            await loadCart();
                            setLoadingCart((prev) => prev = {
                                ...prev,
                                deleteAllItems: false,
                            });
                            dialogCartItems.current?.hide();
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };


    const allowProceedCheckout = () => {
        // se allow for true, não permitir prosseguir para o checkout
        const notAvailableToCheckout = ((cart.list.data.notAvailableToCheckout &&
            cart.list.data.notAvailableToCheckout));

        if (notAvailableToCheckout) {
            // dialogCartItems.current?.show();
            if (!allowProceed.proceed) {
                // * exibir items para remover do carrinho para prosseguir
                dialogCartItems.current?.show();
            };
            return;
        }
        navigate('/checkout/endereco');
    };

    React.useEffect(() => {
        loadCart();
        // if (!cart.list.isLoaded) {
        // } else {
        //     checkNotAvailableItems(cart.list?.data);
        //     checkItemDiscountItems(cart.list?.data);
        // }
    }, []);

    return (
        <Box sx={css.box.wrapper}>
            <Box>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'cart-shopping']}
                    title={'Meu carrinho'}
                    action={loadingChanges && (
                        <Box px={2}>
                            <CircularProgress color='inherit' size={18} />
                        </ Box>
                    )} />
            </Box>
            <Container sx={css.container}>
                <Grid container spacing={2}>
                    <Grid p={0} item lg={8} md={12} sm={12} xs={12}>
                        <Stack
                            spacing={2}
                            sx={css.stack.items}>
                            {!cart.list.isLoaded && cart.list.status === 403 && (
                                <Box py={'0.9375rem'}>
                                    <MessageBox
                                        type={'info'}
                                        message='Faça o login para ver seu carrinho'
                                    />
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                        justifyContent='center'>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            onClick={() => navigate('/auth/login')}>
                                            Fazer login
                                        </Button>
                                    </Stack>
                                </Box>
                            )}
                            {cart.list.isLoaded && !layout.loading.value ?
                                (cart.list.data?.totalProducts > 0 ?
                                    cart.list.data.stores.map((store) => (
                                        <Box sx={{ ...css.box.wrapperStore }}>
                                            <Box sx={{ ...css.box.storeHeader }}>
                                                <AvatarGroup
                                                    align={'right'}
                                                    size={'tiny'}
                                                    borderWidth='none'
                                                    title={store.store.title}
                                                    src={files.load(store.store.images.avatar, 'server')} />
                                            </Box>
                                            <Stack divider={<Box sx={{ ...css.box.divider }}></Box>}>
                                                {store.items.map((item, i) => (
                                                    <BoxContent
                                                        key={`${item.ad._id}-${i}`}
                                                        actions={[
                                                            {
                                                                actionType: 'change',
                                                                actionFunction: () => handleOpenChangeModal({
                                                                    idAd: item.ad._id,
                                                                    idVariation: item.variation._id,
                                                                    variationItemId: item.variationItem._id,
                                                                    quantity: item.quantity,
                                                                    replaceItem: i + 1,
                                                                    stockQuantity: item.variationItem.stockQuantity,
                                                                    colorNameVariation: item.variation.colorName,
                                                                }),
                                                                loading: responseData.id === item.variationItem._id && !responseData.isLoadedModal,
                                                                disabled: (item.notAvailable && item.notAvailable) ?? false,
                                                            },
                                                            {
                                                                actionType: 'delete',
                                                                actionFunction: async () => {
                                                                    await changeItem({
                                                                        action: 'delete',
                                                                        item: {
                                                                            idAd: item.ad._id,
                                                                            idVariation: item.variation._id,
                                                                            variationItemId: item.variationItem._id,
                                                                            quantity: item.quantity,
                                                                        },
                                                                    });
                                                                },
                                                                loading: responseData.id === item.variationItem._id && !responseData.isLoadedDelete,
                                                            },
                                                        ]}>
                                                        <Grid p={0} container>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <Link
                                                                    style={{ textDecoration: 'none' }}
                                                                    to={`/produto/${item.ad._id}?variation=${item.variation._id}&option=${item.variationItem._id}`}>
                                                                    <ProductInline
                                                                        srcImage={files.load(item?.variation?.images[0], 'server')}
                                                                        title={`${item.ad.title} ${item.variationItem.sku?.includes('CPFPP541') ? ` - R$${money.formatNumberToPriceBRL(item.variationItem?.originalValue ?? 0)}` : ''}`}
                                                                        // srcLogoPartner='https://cdn.discordapp.com/attachments/1044241266134745202/1171126350803902474/magalu.png?ex=6564c5e8&is=655250e8&hm=47b9d1ca21056daa55961a31c4b179d8a11f66879bcfc27f7e0d89acadff6a8e&'
                                                                        valueType={applicationType}
                                                                        discount={item.variationItem.oldValue}
                                                                        value={applicationType === 'REDEMPTION' ? item.variationItem.pointValue : item.variationItem.actualValue}
                                                                        cashback={applicationType === 'SHOPPING' ? item.cashback : undefined}
                                                                        children={item?.notAvailable ?
                                                                            <Stack
                                                                                direction={'row'}
                                                                                spacing={0.6}
                                                                                alignItems={'center'}
                                                                                sx={{
                                                                                    color: palette.common.black,
                                                                                    py: 0.4,
                                                                                    px: 1,
                                                                                    borderRadius: '0.25rem',
                                                                                    backgroundColor: palette.cityLights,
                                                                                    fontSize: '0.75rem',
                                                                                }}>
                                                                                <I icon={['far', 'circle-info']} />
                                                                                <Typography fontSize={'0.75rem'}>
                                                                                    Item não disponível no momento
                                                                                </Typography>
                                                                            </Stack> :
                                                                        ''}
                                                                         />
                                                                </Link>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <Box sx={{ ...css.box.quantity }}>
                                                                    {!responseData.isLoadedQuantity && responseData.id === item.variationItem._id ?
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            width: '132px',
                                                                            justifyContent: 'center',
                                                                            py: 2,
                                                                        }}>
                                                                            <CircularProgress color='inherit' size={18} />
                                                                        </Box> : (
                                                                            (!item?.notAvailable && (
                                                                                <ControllQuantity
                                                                                    label
                                                                                    value={item.quantity}
                                                                                    limit={item.variationItem.stockQuantity}
                                                                                    remove={() =>
                                                                                        setTimeout(async () => {
                                                                                            await changeItem({
                                                                                                action: 'remove',
                                                                                                item: {
                                                                                                    idAd: item.ad._id,
                                                                                                    idVariation: item.variation._id,
                                                                                                    variationItemId: item.variationItem._id,
                                                                                                    quantity: item.quantity - 1,
                                                                                                    replaceItem: i + 1,
                                                                                                },
                                                                                            });
                                                                                        }, 0)
                                                                                    }
                                                                                    add={() => {
                                                                                        changeItem({
                                                                                            action: 'add',
                                                                                            item: {
                                                                                                idAd: item.ad._id,
                                                                                                idVariation: item.variation._id,
                                                                                                variationItemId: item.variationItem._id,
                                                                                                quantity: item.quantity + 1,
                                                                                                replaceItem: i + 1,
                                                                                            },
                                                                                        });
                                                                                    }} />
                                                                            ))
                                                                        )
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </BoxContent>
                                                ))}
                                            </Stack>
                                        </Box>
                                    )) :
                                    <Box py={'0.9375rem'}>
                                        <MessageBox
                                            message={cart.list.message}
                                            type={'info'} />
                                    </Box>) :
                                <>
                                    {skel.storeInfo()}
                                    {[0, 1].map(() => (skel.boxContentProduct))}
                                </>
                            }
                        </Stack>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Box sx={{ ...css.box.resumeValues }}>
                            {!layout.loading.value && cart.list.isLoaded && cart.list.data?.totalProducts > 0 ?
                                <>
                                    <Stack spacing={1.5}>
                                        <ExpandedListValues mainValue={{
                                            title: `Subtotal (${cart.list.data.totalProducts} item)`,
                                            value: <Price size='small' type={'REDEMPTION'} value={cart.list.data.redemption.totalProductPoints} />,
                                        }} />
                                        {cart.list.data.totalCashback > 0 &&
                                            <>
                                                <Box sx={{ ...css.box.dividerResume }}></Box>
                                                <ExpandedListValues mainValue={{
                                                    title: 'Cashback',
                                                    value: <Price size='small' type={'REDEMPTION'} value={cart.list.data.totalCashback} />,
                                                }} />
                                            </>
                                        }
                                        <Box sx={{ ...css.box.dividerResume }}></Box>
                                        <ExpandedListValues
                                            mainValue={{
                                                title: 'Taxas',
                                                value:
                                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            {((cart.list.data?.rates?.administrative ?? 0) + (cart.list.data?.rates?.handling ?? 0))} Pontos
                                                        </Typography>
                                                    </Stack>,

                                            }}
                                            expandedChildren={
                                                <Stack spacing={1} ml={'1.25rem'}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Administrativa
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            {cart.list.data.rates?.administrative ?? 0} Pontos
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Manuseio
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            {cart.list.data.rates?.handling ?? 0} Pontos
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            }
                                        ></ExpandedListValues>
                                        <ExpandedListValues
                                            mainValue={{
                                                title: 'Valor do frete',
                                                value:
                                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            {cart.list.data?.redemption.pointsShippingValue ?? 0} Pontos
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            /
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            R$ {money.formatNumberToPriceBRL(cart.list.data.totalShipping)}
                                                        </Typography>
                                                    </Stack>,
                                            }}
                                            expandedChildren={
                                                <Stack spacing={1} ml={'1.25rem'}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Em pontos
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            {cart.list.data?.redemption.pointsShippingValue ?? 0} Pontos
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Em reais
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            R$ {money.formatNumberToPriceBRL(cart.list.data.totalShipping)}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            } />

                                            {itemsDiscounts.length > 0 && (
                                                 <ExpandedListValues
                                                    mainValue={{
                                                        title: 'Total de descontos',
                                                        value:
                                                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                                <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                                    - {cart.list.data?.discount?.itemDiscountValue ?? 0} Pontos
                                                                </Typography>
                                                            </Stack>,
                                                    }}
                                                    expandedChildren={
                                                        <Stack spacing={1} ml={'1.25rem'}>
                                                            {itemsDiscounts.map((item, i) => (
                                                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                    <Typography sx={{ ...css.texts.expandedValueText }}> {item.ad.title}
                                                                    </Typography>
                                                                    <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                                        - {(item.discount.itemDiscountValue ?? 0)} Pontos
                                                                          {item.itemDiscount?.endDate && (<p>Desconto válido até {formatShortDate(item.itemDiscount.endDate)}</p>)}
                                                                    </Typography>
                                                                </Stack>
                                                            ))}
                                                        </Stack>
                                                    } />
                                            )}

                                        <Box sx={{ ...css.box.dividerResume }}></Box>
                                        <TitleSectionShopping name={'Total'} />
                                        <ExpandedListValues
                                            mainValue={{
                                                title: 'A pagar',
                                                value:
                                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            {cart.list.data.redemption.totalPoints} Pontos
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            /
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedMainValueChildren }}>
                                                            R$ {money.formatNumberToPriceBRL(cart.list.data?.total)}
                                                        </Typography>
                                                    </Stack>,
                                            }}
                                            expandedChildren={
                                                <Stack spacing={1} ml={'1.25rem'}>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Em pontos
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            {cart.list.data.redemption.totalPoints ?? 0} Pontos
                                                        </Typography>
                                                    </Stack>
                                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                        <Typography sx={{ ...css.texts.expandedValueText }}> Em reais
                                                        </Typography>
                                                        <Typography sx={{ ...css.texts.expandedValueChildren }}>
                                                            R$ {money.formatNumberToPriceBRL(cart.list.data?.total)}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            } />
                                        <Box sx={{ ...css.box.dividerResume }}></Box>
                                    </Stack>
                                    <Box sx={{ ...css.box.continue }}>
                                        {/* <Link to={(
                                            (cart.list.data.notAvailableToCheckout &&
                                                !cart.list.data.notAvailableToCheckout)
                                        ) ? '/checkout/endereco' : ''}> */}
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            disabled={!loadingCart.cart}
                                            onClick={allowProceedCheckout}
                                        >
                                            Continuar {!loadingCart.cart && <CircularProgress
                                                size={14}
                                                sx={{ color: palette.common.white, ml: 1 }}
                                            />}
                                        </Button>
                                        {/* </Link> */}
                                    </Box> </> :
                                skel.resumeCart
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Template.MuiDialog
                maxWidth='sm'
                showDialogTitle
                title='Verifique seu carrinho'
                fullScreen={!mediaQuery.md}
                ref={dialogCartItems}
                onCancel={{
                    action: !loadingCart.deleteAllItems ?
                        () => dialogCartItems.current?.hide() :
                        () => { },
                }}
                onConfirm={{
                    text: [(allowProceed.items.length > 1 ?
                        'Remover todos'.toUpperCase() :
                        'Remover item'.toUpperCase()),
                    loadingCart.deleteAllItems && <CircularProgress
                        size={14}
                        sx={{ color: palette.primaryColor, ml: 1 }}
                    />],
                    action: () => deleteAllItemsOnCart(),
                }}
            >
                <Alert severity="warning">Alguns itens do seu carrinho não estão disponíveis</Alert>
                {!allowProceed.proceed && allowProceed.items.map((item, i, arr) => (
                    <>
                        <BoxContent
                            actions={[
                                {
                                    actionType: 'delete',
                                    actionFunction: async () => {
                                        await changeItem({
                                            action: 'delete',
                                            item: {
                                                idAd: item.ad._id,
                                                idVariation: item.variation._id,
                                                variationItemId: item.variationItem._id,
                                                quantity: item.quantity,
                                            },
                                        });
                                    },
                                },
                            ]}>
                            {loadingCart.deleteAllItems && (
                                <Box sx={css.boxContentOverlay} />
                            )}
                            <ProductInline
                                srcImage={files.load(item?.variation?.images[0], 'server')}
                                title={item.ad.title}
                                valueType={applicationType}
                                discount={item.variationItem.oldValue}
                                value={applicationType === 'REDEMPTION' ? item.variationItem.pointValue : item.variationItem.actualValue}
                                cashback={applicationType === 'SHOPPING' ? item.cashback : undefined} />
                        </BoxContent>
                        {i < arr.length - 1 && <Divider color={palette.lightBg} />}
                    </>
                ))}
            </Template.MuiDialog>
            <Template.MuiDialog
                maxWidth='sm'
                showDialogTitle={!mediaQuery.md}
                title='Escolha os detalhes deste produto'
                fullScreen={!mediaQuery.md}
                ref={dialogChangesItem}>
                <Box sx={{ ...css.modal.box.wrapper }}>
                    {ads.byId.item.isLoaded ?
                        <>
                            <Box sx={{ ...css.modal.box.col1 }}>
                                <Box sx={{ ...css.modal.box.itemPicture }}>
                                    <Image
                                        src={imageVariation()}
                                        alt={ads.byId.item.data.title}
                                        objectFit='contain'
                                        maxHeight={css.modal.image} />
                                    <Box sx={{ ...css.modal.box.itemTexts }}>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            fontSize={16}>
                                            {ads.byId.item.data.title}
                                        </Typography>
                                        <Price
                                            type={applicationType}
                                            size={'medium'}
                                            discount={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.oldValue}
                                            value={applicationType === 'REDEMPTION' ? Number(ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.pointValue) : Number(ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.actualValue)} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ ...css.modal.box.col2 }}>
                                {mediaQuery.md && (
                                    <>
                                        <IconButton
                                            onClick={() => dialogChangesItem.current?.hide()}
                                            aria-label="close"
                                            sx={{ ...css.modal.iconButton.close }}>
                                            <I icon={['far', 'times']} />
                                        </IconButton>
                                        <Typography variant='h4' sx={{ ...css.modal.title }} >
                                            Escolha os detalhes deste produto
                                        </Typography>
                                    </>
                                )}
                                <Stack mb={2} spacing={1}>
                                    <Stack spacing={1} direction={'row'}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.modal.titleSection }}>
                                            Variação:
                                        </Typography>
                                        <Typography
                                            variant="body2">
                                            {selectedAdItem.colorNameVariation}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={1} direction={'row'}>
                                        {ads.byId.item.data.variations.map((variation) => (
                                            <VariationItem
                                                onClick={() => setSelectedAdItem((prev) => prev = {
                                                    ...prev,
                                                    idAd: ads.byId.item.data._id,
                                                    idVariation: variation._id,
                                                    quantity: 1,
                                                    variationItemId: variation?.items[0]?._id,
                                                    stockQuantity: variation.items[0].stockQuantity,
                                                    colorNameVariation: variation.colorName,
                                                })}
                                                active={variation._id === selectedAdItem.idVariation}
                                                srcImage={files.load(variation.images[0], 'server')} />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack mb={2} spacing={1}>
                                    <Typography
                                        variant="body2"
                                        sx={{ ...css.modal.titleSection }}>
                                        Opções:
                                    </Typography>
                                    <Stack direction={'row'} spacing={1}>
                                        {ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.
                                            items.map((item, i) => (
                                                <Item
                                                    key={`item-var-${i}`}
                                                    onClick={() => setSelectedAdItem(
                                                        (prev) => prev = {
                                                            ...prev,
                                                            quantity: 1,
                                                            variationItemId: item._id,
                                                            stockQuantity: item.stockQuantity,
                                                        },
                                                    )}
                                                    className={selectedAdItem.variationItemId === item._id ? 'active' : ''}>
                                                    <label>
                                                        {item.name}
                                                    </label>
                                                </Item>
                                            ))}
                                    </Stack>
                                </Stack>
                                {selectedAdItem.stockQuantity > 0 ?
                                    <Stack mb={2} spacing={1}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.modal.titleSection }}>
                                            Quantidade:
                                        </Typography>
                                        <ControllQuantity
                                            value={selectedAdItem.quantity}
                                            limit={selectedAdItem.stockQuantity}
                                            remove={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity - 1,
                                                },
                                            )}
                                            add={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity + 1,
                                                },
                                            )} />
                                    </Stack> :
                                    <Stack mb={2}>
                                        <Typography
                                            sx={{ ...css.modal.noStockText }}
                                            variant='caption'>
                                            <I
                                                style={{ marginRight: '5px' }}
                                                icon={['far', 'circle-info']} />
                                            Item sem estoque no momento
                                        </Typography>
                                    </Stack>
                                }
                                <Button
                                    onClick={() => changeItem({
                                        action: 'modal',
                                        item: selectedAdItem,
                                    })}
                                    disabled={!responseData.isLoadedModal || selectedAdItem.stockQuantity === 0}
                                    fullWidth
                                    variant="contained"
                                    size="large">
                                    Atualizar {!responseData.isLoadedModal &&
                                        <CircularProgress
                                            color="inherit"
                                            size={14}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                            </Box>
                        </> : 'Carregando...'
                    }
                </Box>
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { Cart };
