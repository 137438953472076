/* eslint-disable max-len */
import React from 'react';
import { Box, Button, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import {
    BoxContent,
    HeaderPage,
    MessageBox,
    TabPanel,
    Image,
} from '@components';
import Cpp from '@img/cpp.png';
import { sx } from './sx';
import { files, handleSetURLParams, styles } from '@utils';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@hooks/user';
import { StatusTagOrder } from '../components/status-tag-order';
import { ProductItem } from '../components/product-item';

function a11yProps(index: number) {
    return {
        'id': 'simple-tab-' + index,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const opened = ['AWAITING_PAYMENT', 'TAG_GENERATED', 'POSTED', 'TAG_GENERATED', 'PAID', 'PROCESSING', 'PARTIALLY_PAID', 'AWAITING_PARTNER'];
const concluded = ['PAYMENT_NOT_APPROVED', 'CANCELED', 'FINISHED', 'PARTIALLY_CANCELED', 'REFUNDED', 'PARTIALLY_REFUNDED'];

const List: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const urlSearch = new URLSearchParams(window.location.search);

    const navigate = useNavigate();
    const { purchase } = useUser();

    const [limitList, setLimitList] = React.useState<number>(3);
    const [valueTab, setValueTab] = React.useState(Number(urlSearch.get('tab')));

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        handleSetURLParams('tab', String(newValue));
        setLimitList(3);
        setValueTab(newValue);
    };

    React.useEffect(() => {
        if (!purchase.orders.list.isLoaded ||
            purchase.orders.list.message === 'Token expirado') {
            purchase.orders.load();
        }
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                noPadding
                icon={['far', 'boxes-stacked']}
                titleAlign={'default'} title={'Meus Pedidos'} />
            <Stack
                divider={ <Box sx={{ ...css.box.divider }}></Box> }
                sx={{ ...css.stack }}>
                {purchase.orders.list.isLoaded ? ((purchase.orders.list.data.length > 0) ||
                (purchase.orders.listDevolution.data.length > 0) ?
                    <>
                        <Box>
                            <Box sx={{ ...css.box.tabs }}>
                                <Tabs
                                    value={valueTab}
                                    onChange={handleChangeTab}
                                    sx={{ ...css.tabs }}>
                                    <Tab label="Em aberto" {...a11yProps(0)} />
                                    <Tab label="Concluídos" {...a11yProps(2)} />
                                    <Tab label="Devoluções" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                        </Box>
                        <TabPanel value={valueTab} index={0}>
                            <Stack spacing={mediaQuery.lg ? 0.5 : 1}>
                                {purchase.orders.list.data.filter((itemFilter) => opened.includes(itemFilter.currentStatus)).length > 0 ?
                                    <>
                                        {purchase.orders.list.data.filter((itemFilter) => opened.includes(itemFilter.currentStatus)).slice(0, limitList).map((item) => {
                                            return (
                                                <Stack key={item._id+'-opened'} sx={{ ...css.box.wrapperStore }}>
                                                    <Box sx={{ ...css.box.storeHeader }}>
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Image
                                                                src={files.load(item.store.images.avatar, 'server')}
                                                                alt={item.store.title}
                                                                objectFit='contain'
                                                                maxHeight={mediaQuery.md ? 36 : 30} />
                                                            <StatusTagOrder status={item.currentStatus} />
                                                        </Stack>
                                                    </Box>
                                                    <BoxContent
                                                        link={`/meus-pedidos/detalhes/${item._id}`}
                                                        actions={[
                                                            {
                                                                actionFunction: () => navigate(`/meus-pedidos/detalhes/${item._id}`),
                                                                actionType: 'right',
                                                                noLegend: true,
                                                            },
                                                        ]}>
                                                        <Stack spacing={1}>
                                                            {!item.recharge ?
                                                                item?.items?.map((itemAd, i) => (
                                                                    <Box key={`${itemAd?._id}-${i}`}>
                                                                        <ProductItem
                                                                            srcImage={files.load(itemAd?.variation?.images[0], 'server')}
                                                                            title={itemAd?.ad?.title} />
                                                                    </Box>
                                                                )) :
                                                                <ProductItem
                                                                    srcImage={Cpp}
                                                                    title={'Recarga de Cartão'} />
                                                            }
                                                        </Stack>
                                                    </BoxContent>
                                                </Stack>
                                            );
                                        })}
                                        <Stack justifyContent={'center'}>
                                            {limitList < purchase.orders.list.data.filter((itemFilter) => opened.includes(itemFilter.currentStatus)).length ?
                                                <Button
                                                    onClick={() => setLimitList(limitList + 3)}
                                                    variant='text'>
                                                    Mostrar mais
                                                </Button> :
                                                !(purchase.orders.list.data.filter((itemFilter) => opened.includes(itemFilter.currentStatus)).length <= 3) &&
                                                <Button
                                                    onClick={() => setLimitList(3)}
                                                    variant='text'>
                                                    Mostrar menos
                                                </Button>
                                            }
                                        </Stack>
                                    </> :
                                    <Box p={2}>
                                        <MessageBox
                                            message={'Nenhum registro encontrado'}
                                            type={'info'} />
                                    </Box>
                                }
                            </Stack>
                        </TabPanel>
                        <TabPanel value={valueTab} index={1}>
                            <Stack spacing={mediaQuery.lg ? 0.5 : 1}>
                                {purchase.orders.list.data.filter((itemFilter) => concluded.includes(itemFilter.currentStatus)).length > 0 ?
                                    <>
                                        {purchase.orders.list.data.filter((itemFilter) => concluded.includes(itemFilter.currentStatus)).slice(0, limitList).map((item) => {
                                            return (
                                                <Stack key={item._id+'-concluded'} sx={{ ...css.box.wrapperStore }}>
                                                    <Box sx={{ ...css.box.storeHeader }}>
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Image
                                                                src={files.load(item.store.images.avatar, 'server')}
                                                                alt={item.store.title}
                                                                objectFit='contain'
                                                                maxHeight={mediaQuery.md ? 36 : 30} />
                                                            <StatusTagOrder status={item.currentStatus} />
                                                        </Stack>
                                                    </Box>
                                                    <BoxContent
                                                        link={`/meus-pedidos/detalhes/${item._id}`}
                                                        actions={[
                                                            {
                                                                actionFunction: () => navigate(`/meus-pedidos/detalhes/${item._id}`),
                                                                actionType: 'right',
                                                                noLegend: true,
                                                            },
                                                        ]}>
                                                        <Stack spacing={1}>
                                                            {!item.recharge ?
                                                                item?.items?.map((itemAd, i) => (
                                                                    <Box key={`${itemAd?._id}-${i}`}>
                                                                        <ProductItem
                                                                            srcImage={files.load(itemAd?.variation?.images[0], 'server')}
                                                                            title={itemAd?.ad?.title} />
                                                                    </Box>
                                                                )) :
                                                                <ProductItem
                                                                    srcImage={Cpp}
                                                                    title={'Recarga de Cartão'} />
                                                            }
                                                        </Stack>
                                                    </BoxContent>
                                                </Stack>
                                            );
                                        })}
                                        <Stack justifyContent={'center'}>
                                            {limitList < purchase.orders.list.data.filter((itemFilter) => concluded.includes(itemFilter.currentStatus)).length ?
                                                <Button
                                                    onClick={() => setLimitList(limitList + 3)}
                                                    variant='text'>
                                                    Mostrar mais
                                                </Button> :
                                                !(purchase.orders.list.data.filter((itemFilter) => concluded.includes(itemFilter.currentStatus)).length <= 3) &&
                                                <Button
                                                    onClick={() => setLimitList(3)}
                                                    variant='text'>
                                                    Mostrar menos
                                                </Button>
                                            }
                                        </Stack>
                                    </> :
                                    <Box p={2}>
                                        <MessageBox
                                            message={'Nenhum registro encontrado'}
                                            type={'info'} />
                                    </Box>
                                }
                            </Stack>
                        </TabPanel>
                        <TabPanel value={valueTab} index={2}>
                            <Stack spacing={mediaQuery.lg ? 0.5 : 1}>
                                {purchase.orders.listDevolution.data.length > 0 ?
                                    <>
                                        {purchase.orders.listDevolution.data.map((item) => {
                                            return (
                                                <Stack key={item._id+'-devolution'} sx={{ ...css.box.wrapperStore }}>
                                                    <Box sx={{ ...css.box.storeHeader }}>
                                                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Image
                                                                src={files.load(item.store.images.avatar, 'server')}
                                                                alt={item.store.title}
                                                                objectFit='contain'
                                                                maxHeight={mediaQuery.md ? 36 : 30} />
                                                            <StatusTagOrder status={item.currentStatus} />
                                                        </Stack>
                                                    </Box>
                                                    <BoxContent
                                                        link={`/meus-pedidos/detalhes-devolucao/${item._id}`}
                                                        actions={[
                                                            {
                                                                actionFunction: () => navigate(`/meus-pedidos/detalhes-devolucao/${item._id}`),
                                                                actionType: 'right',
                                                                noLegend: true,
                                                            },
                                                        ]}>
                                                        <Stack spacing={1}>
                                                            {item.devolution.itemsDevolution.map((itemAd, i) => (
                                                                <Box key={`${itemAd.variation._id}-${i}`}>
                                                                    <ProductItem
                                                                        srcImage={files.load(itemAd?.variation?.images[0], 'server')}
                                                                        title={itemAd.ad.title} />
                                                                </Box>
                                                            ))}
                                                        </Stack>
                                                    </BoxContent>
                                                </Stack>
                                            );
                                        })}
                                    </> :
                                    <Box p={2}>
                                        <MessageBox
                                            message={'Nenhum registro encontrado'}
                                            type={'info'} />
                                    </Box>
                                }
                            </Stack>
                        </TabPanel>
                    </> :
                    <Box pb={2}>
                        <MessageBox
                            message={purchase.orders.list.message}
                            type={'info'} />
                    </Box>) :
                    <>
                        <Box sx={{ ...css.box.wrapperStore }}>
                            <Skeleton height={48} variant='rectangular' />
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                spacing={1}
                                sx={{ ...css.box.storeHeader }}>
                                <Skeleton height={40} width={80} variant='rectangular' />
                            </Stack>
                            <Stack divider={ <Box sx={{ ...css.box.divider }}></Box> }>
                                {Array.from(Array(2).keys()).map((_, i) => (
                                    <SkeletonOrderList key={`skel-${i}`} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                }
            </Stack>
        </Box>
    );
};

const SkeletonOrderList = () => {
    return (
        <>
            <Box
                p={2}
                pr={0.5}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={1}>
                    <Stack spacing={1}>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Skeleton height={50} width={50} variant='rounded' />
                            <Skeleton width={120} variant='text' />
                        </Stack>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Skeleton height={50} width={50} variant='rounded' />
                            <Skeleton width={120} variant='text' />
                        </Stack>
                    </Stack>
                </Stack>
                <Skeleton width={38} height={38} variant='rounded' />
            </Box>
        </>
    );
};

export { List };
